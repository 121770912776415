import {gql, useApolloClient, useMutation} from "@apollo/client";
import {Behaviour} from "./Behaviour";
import React from "react";
import {allBehaviourObservationFields, BehaviourObservation} from "./BehaviourObservation";
import {buttonStyle} from "../utils/styles";
import {debug} from "../services/Debugger";

import {forgetAssessmentResults} from "../services/CachedAssessmentResults";

const createBehaviourObservation = gql`
    ${allBehaviourObservationFields}
    mutation CreateBehaviourObservation($behaviour: ID!, $isPresent: Boolean!) {
        createBehaviourObservation(id: $behaviour, isPresent: $isPresent) {
            ...AllBehaviourObservationFields
        }
    }
`;

export function BehaviourPoll({
                                  behaviour, className, resetAfterPoll = false, callback = (behaviour, isPresent) => {
    }
                              }) {
    const client = useApolloClient();
    const [createObservation, {data, loading, error, reset}] = useMutation(createBehaviourObservation);

    function updateBehaviourObservationList(cache, existing, observation) {
        debug("Updating cache: adding observation to behaviourObservationList", observation);
        return [
            ...existing,
            {
                ...observation,
                behaviour: {__ref: cache.identify(observation.behaviour)}
            }
        ];
    }

    const recordObservation = (isPresent) => {
        createObservation({
            variables: {behaviour: behaviour.id, isPresent: isPresent},
            update(cache, {data: {createBehaviourObservation}}) {
                forgetAssessmentResults(client);
                cache.modify({
                    fields: {
                        behaviourObservationList(existing = []) {
                            return updateBehaviourObservationList(cache, existing, createBehaviourObservation);
                        }
                    }
                })
            }
        })
            .then(() => {
                callback(behaviour, isPresent);
                resetAfterPoll && reset();
            });
    }

    if (loading) return 'Submitting';
    if (error) return `Submission error! ${error.message}`;
    if (data) return <BehaviourObservation className={className} observation={data.createBehaviourObservation}/>;

    return <div className={className}>
        <Behaviour behaviour={behaviour}/>

        <ExpandedButtonPanel onYes={() => recordObservation(true)} onNo={() => recordObservation(false)}/>
    </div>;
}

function ExpandedButtonPanel({onYes, onNo}) {
    return <div className="mt-1 grid grid- grid-cols-2 gap-1">
        <button className={buttonStyle({
            bg: "bg-green-500 hover:bg-green-600"
        }).className} onClick={onYes}>Yes, this is true for us
        </button>
        <button className={buttonStyle({
            bg: "bg-red-500 hover:bg-red-600"
        }).className} onClick={onNo}>No, this is not the case for us
        </button>
    </div>;
}

