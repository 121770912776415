import React, {useEffect, useState} from "react";
import {gql, useApolloClient, useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {GraphQlError} from "../components/GraphQLError";
import {allBehaviourObservationFields, BehaviourObservation} from "../components/BehaviourObservation";
import {allAssessmentFields, allCapabilityFields} from "../components/Assessment";
import {useParams} from "react-router-dom";
import {Timestamp} from "../components/Timestamp";
import {ProgressBar} from "../components/ProgressBar";
import {Subheading} from "../components/Typography";
import {rememberAssessmentResults} from "../services/CachedAssessmentResults";

const allResultSnapshotFields = gql`
    ${allBehaviourObservationFields}
    ${allCapabilityFields}
    fragment AllResultSnapshotFields on AssessmentResultSnapshot {
        date
        observationList {
            ...AllBehaviourObservationFields
        }
        capabilityScoreList {
            capability {
                ...AllCapabilityFields
            }
            score {
                actual
                actualMaximum # potential maximum based on answered questions
            }
        }
    }
`;

export const GET_ASSESSMENT_RESULT_BY_ID = gql`
    ${allResultSnapshotFields}
    ${allAssessmentFields}
    query getAssessmentResult($assessmentId: ID!) {
        assessmentResult(id: $assessmentId) {
            assessment {
                ...AllAssessmentFields
            }
            current { # ordered by date from old to new
                ...AllResultSnapshotFields
            }
        }
    }
`;

export const GET_ASSESSMENT_RESULT_WITH_HISTORY_BY_ID = gql`
    ${allResultSnapshotFields}
    ${allAssessmentFields}
    query getAssessmentResult($assessmentId: ID!) {
        assessmentResult(id: $assessmentId) {
            assessment {
                ...AllAssessmentFields
            }
            history { # ordered by date from old to new
                ...AllResultSnapshotFields
            }
        }
    }
`;

export function AssessmentResultRoute() {
    const client = useApolloClient();

    const {id} = useParams();
    const {loading, error, data} = useQuery(GET_ASSESSMENT_RESULT_WITH_HISTORY_BY_ID, {variables: {assessmentId: id}});
    const [snapshot, setSnapshot] = useState();

    if (loading) return <Center><p>Loading...</p></Center>;
    if (error) return <GraphQlError error={error}/>;

    rememberAssessmentResults(client, data.assessmentResult);

    if (!data.assessmentResult.history.length)
        return <div>
            No observations recorded yet.
        </div>;

    return <div className="w-full">
        <SnapshotSlider snapshots={data.assessmentResult.history} setSnapshot={setSnapshot}/>
        <AssessmentResult snapshot={snapshot}/>
        <Subheading className="mt-8">Observations</Subheading>
        <div>
            {snapshot && snapshot.observationList.map(o => <BehaviourObservation key={o.behaviour.id}
                                                                                 observation={o}/>)}
        </div>
    </div>
}

function SnapshotSlider({
                            snapshots, setSnapshot = (snapshot) => {
    }
                        }) {
    const [snapshotDate, setSnapshotDate] = useState();

    useEffect(() => {
        if (snapshots.length) selectSnapshot(dates.slice(-1)[0]);
    }, [snapshots]);

    const dates = []
    const snapshotMap = {}
    snapshots.forEach(s => {
        const date = new Date(s.date);
        dates.push(date); // preserve order
        snapshotMap[date] = s;
    });

    function selectSnapshot(date) {
        const selectedSnapshotDate = dates.filter(d => d <= date).slice(-1)[0];
        setSnapshotDate(selectedSnapshotDate);
        setSnapshot(snapshotMap[selectedSnapshotDate]);
    }

    const startTime = dates[0].getTime();
    const endTime = dates.slice(-1)[0].getTime();

    return <div>
        <div className="flex justify-between -mb-5 mt-1 border-x-2 px-3 pb-3 border-gray-200">
            <div><Timestamp date={snapshots[0].date}/></div>
            <div><Timestamp date={snapshots.slice(-1)[0].date}/></div>
        </div>
        <div className="w-full border-x-[10px] border-transparent box-border relative -z-10">
            {dates.slice(1, -1).map(d => <div className="h-2 absolute mt-2 top-0 left-0 border-r-2 border-black"
                                              style={{width: `${position(d)}%`}}/>)}
        </div>
        <input type="range"
               value={snapshotDate && snapshotDate.getTime()}
               min={startTime} max={endTime}
               className="w-full h-1 accent-soc-cheeks appearance-none bg-gray-100"
               onChange={(e => selectSnapshot(e.target.value))}/>
    </div>;

    function position(date) {
        return 100 * (date.getTime() - startTime) / (endTime - startTime);
    }
}

function AssessmentResult({snapshot}) {
    return snapshot && <div className="mt-4">
        Date: <span className="font-bold"><Timestamp date={snapshot.date}/></span>
        <div className="pt-2">
            {snapshot.capabilityScoreList.map(c => <CapabilityProgress key={c.capability.name} capability={c.capability}
                                                                       score={c.score}/>)}
        </div>
    </div>;
}

function CapabilityProgress({capability, score}) {

    return <div>
        {capability.name}
        <ProgressBar min={capability.minimum} max={capability.maximum} actual={score.actual}
                     potential={score.actualMaximum}/>
    </div>
}
