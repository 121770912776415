import {cc} from "./Typography";
import React from "react";

export function ProgressBar({className, min, max, actual, potential,
                                height = "h-4",
                                showValue = true}) {
    let score = percentage(actual);
    return <div className={cc(className, height, "flex bg-gray-100")}>
        <span className={`h-full flex-none bg-soc-cheeks-dark text-nowrap text-xs text-center text-white`}
              style={{width: `${score}%`}}>{showValue && `${score.toFixed(0)} %`}</span>
        <div className={`h-full flex-none inline-block bg-soc-face`}
             style={{width: `${percentage(potential - actual)}%`}}></div>
    </div>

    function percentage(value) {
        return 100 * (value - min) / (max - min);
    }
}