import {AuthContext} from "react-oauth2-code-pkce"
import React, {useContext} from "react";
import {cc, Heading, Subheading} from "./Typography";
import {useNavigate} from "react-router-dom";
import {buttonStyle} from "../utils/styles";

export function TokenInformation({title, className}) {
  const {error, token, tokenData, idToken, idTokenData} = useContext(AuthContext);

  if (error)
    return <div>Error loading user information: {error}</div>;

  return <div className={className}>
    {title && <Heading>{title}</Heading>}
    <Token name="Access token" data={tokenData}>{token}</Token>
    <Token name="ID token" data={idTokenData}>{idToken}</Token>
  </div>;

  function Token({name, data, children}) {
    return <div className="mb-4">
      <Subheading>{name}</Subheading>
      <div className="italic my-2 overflow-auto max-w-full rounded-md bg-gray-100 p-2">{children}</div>
      <pre className="text-xs">{JSON.stringify(data, null, 2)}</pre>
    </div>;
  }
}

export function LoginButton({className, children}) {
  const {logIn} = useContext(AuthContext);

  return <button onClick={() => logIn()}
                 className={cc(className, buttonStyle({bg: "bg-soc-cheeks-dark hover:bg-soc-cheeks"}).className)}>
    {children}
  </button>;
}

export function LogoutButton({children}) {
  const {logOut} = useContext(AuthContext);
  const navigate = useNavigate();

  function logoutAndGoHome() {
    logOut();
    navigate("/");
  }

  return <button onClick={() => logoutAndGoHome()}
                 className={buttonStyle({bg: "bg-soc-cheeks-dark hover:bg-soc-cheeks"}).className}>
    {children}
  </button>
}
