import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Center} from "../components/Center";
import {allBehaviourFields} from "../components/Behaviour";
import {GraphQlError} from "../components/GraphQLError";
import {cc, Heading} from "../components/Typography";
import {Link} from "react-router-dom";
import {BehaviourPoll} from "../components/BehaviourPoll";
import {Instructions} from "../components/Instructions";
import {OnboardingAssessment} from "../components/OnboardingAssessment";
import {WelcomeToSocratesAdvice} from "../components/Welcome";

const GET_TODAYS_CHALLENGE = gql`
    ${allBehaviourFields}
    query {
        todaysChallenge {
            ...AllBehaviourFields
        }
    }
`;

export function HomeRoute() {
    const { loading, error, data } = useQuery(GET_TODAYS_CHALLENGE);

    if (loading) return <Center><p>Loading...</p></Center>;

    if (error) return <GraphQlError error={error} />;

    return <div className="w-full">
        <WelcomeToSocratesAdvice />
        <OnboardingAssessment>
            <CommonActions />
            {/*<TodaysPoll behaviour={data.todaysChallenge}/>*/}
        </OnboardingAssessment>
    </div>
}

function CommonActions() {
    const actions = [
        <Action key="result" href="/assessments/67d5ff70-3818-462d-88c0-d389ab9b5e23/result">Check out how your team is doing</Action>,
        <Action key="survey" href="/assessments/b1bef7e5-01ed-404b-98d3-5a988a617fbc/survey">Take a survey</Action>
    ]

    return <div className={cc("grid grid-cols-1 gap-2 items-stretch", actions.length > 2 ? "md:grid-cols-3" : "md:grid-cols-2")}>
        {actions}
    </div>;
}

function Action({href, unavailable = false, children}) {
    return <Link to={href} className={cc(
        unavailable ? "bg-gray-100 text-gray-600" : "bg-soc-face hover:bg-soc-cheeks",
        "flex items-center justify-center text-lg text-center p-8"
    )}>
            {children}
    </Link>;
}

function TodaysPoll({behaviour}) {
    return <>
        <Heading>If nothing else, check out today's challenge</Heading>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-6">
            <BehaviourPoll className="col-span-1 md:col-span-2" behaviour={behaviour}/>
            <Instructions narrow/>
        </div>
    </>;
}