import React from "react";
import {TokenInformation} from "../components/Authentication";
import {WhenAuthorized} from "../components/Authorization.helper";
import {AdminTools} from "../components/AdminTools";

export function ProfileRoute() {
    return <div className="max-w-full">
        <WhenAuthorized role="admin">
            <AdminTools title="Admin tools" className="mt-8"/>
            <TokenInformation title="User tokens"/>
        </WhenAuthorized>
    </div>;
}
