import {NavLink, Outlet} from "react-router-dom";
import React, {useContext} from "react";
import {Footer} from "../components/Footer";
import {AuthContext} from "react-oauth2-code-pkce";
import {MainMenu, SubMenu} from "../components/MainMenu";
import {LogoutButton} from "../components/Authentication";

export function ApplicationPage() {
    return <div>
        <Header/>
        <div className="flex flex-col p-2 max-w-screen-2xl w-full min-h-max flex-grow mx-auto">
            <div className="relative grow flex px-1 md:px-4">
                <Outlet/>
            </div>
            <Footer className="px-1 md:px-4"/>
        </div>
    </div>;
}

export function Header({className}) {
    const {idTokenData} = useContext(AuthContext);

    return <div className="-mt-2 sticky top-0 shadow-md bg-white z-40">
        <MainMenu className="max-w-screen-2xl mx-auto px-3 pt-2 md:px-6">
            <NavLink to="/assessments" aria-current="page">Assessments</NavLink>
            <NavLink to="/polls" aria-current="page">Polls</NavLink>
            <SubMenu image={idTokenData?.picture} name={idTokenData?.name}>
                <NavLink to="/profile" aria-current="page">Profile</NavLink>
                <LogoutButton>Logout</LogoutButton>
            </SubMenu>
        </MainMenu>
    </div>;

}