import {gql} from "@apollo/client";
import {allBehaviourFields} from "./Behaviour";
import React from "react";

export const allCapabilityFields = gql`
    ${allBehaviourFields}
    fragment AllCapabilityFields on Capability {
        assessment {
            id
        }
        name
        minimum
        maximum
        impactList {
            behaviour {
                ...AllBehaviourFields
            }
            whenTrue
            whenFalse
        }
    }
`;

export const allAssessmentFields = gql`
    ${allBehaviourFields}
    ${allCapabilityFields}
    fragment AllAssessmentFields on Assessment {
        id
        name
        description
        source
        capabilityList {
            ...AllCapabilityFields
        }
        behaviourList {
            ...AllBehaviourFields
        }
    }
`;

export const getAssessmentList = gql`
    ${allAssessmentFields}
    query {
        assessmentList {
            ...AllAssessmentFields
        }
    }
`;

export function Assessment({assessment}) {
    return <div className="my-8">
        Id: {assessment.id}<br/>
        Name: {assessment.name}<br/>
        Description: {assessment.description} <br/>
        Source: {assessment.source} <br/>
        <div className="border-l-2 border-soc-grey pl-2 my-2">
            Capabilities
            {assessment.capabilityList.map(c => <Capability
                capability={c}>{c.name} [{c.minimum}-{c.maximum}]</Capability>)}
        </div>
        <div className="border-l-2 border-soc-grey pl-2 my-2">
            Behaviours
            {assessment.behaviourList.map(b => <div className="ml-2">- {b.summary}</div>)}
        </div>
    </div>;
}

function Capability({capability, children}) {
    return <div className="border-l-2 border-soc-grey pl-2 my-2">
        {children}
        {capability.impactList.map(i => <div>- {i.behaviour.summary} (true: {i.whenTrue}, false: {i.whenFalse})</div>)}
    </div>
}